import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import CallStatsIcon from '@mui/icons-material/BarChart'; // Update these icons as required
import ShiftSchedulerIcon from '@mui/icons-material/Schedule';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';

export default function Dispatcher() {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Box display="flex">
      <Box width={collapsed ? 60 : 190} borderRight="1px solid gray">
        <IconButton onClick={toggleCollapse}>
          {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
        <List component="nav">
          <ListItem button component={Link} to="/dispatcher/shiftscheduler">
            <ShiftSchedulerIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'} ml={1}><ListItemText primary="ShiftScheduler" /></Box>
          </ListItem>
          <ListItem button component={Link} to="/dispatcher/messages">
            <AppSettingsAltIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'} ml={1}><ListItemText primary="MessageSettings" /></Box>
          </ListItem>
        </List>
      </Box>
      <Box p={1} flex="auto">
        <Outlet />
      </Box>
    </Box>
  );
}
