import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyBH64UjdzL5-99NnMWtPTuggE_lCMjkIT0",
  authDomain: "smstocall2.firebaseapp.com",
  projectId: "smstocall2",
  storageBucket: "smstocall2.appspot.com",
  messagingSenderId: "589509325578",
  appId: "1:589509325578:web:ad90a0ba8fd59f219d00f3"
};

export default firebaseConfig;