import React from 'react';

function Integrations() {
  return (
    <div>
      <h1>Integrations Page</h1>
    </div>
  );
}

export default Integrations;
