import React from 'react';

function Adjust() {
  return (
    <div>
      <h1>Adjust Page</h1>
    </div>
  );
}

export default Adjust;
