import React from 'react';

function StockOut() {
  return (
    <div>
      <h1>StockOut Page</h1>
    </div>
  );
}

export default StockOut;
