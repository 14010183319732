import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardContent, Typography, Hidden } from '@mui/material';
import { getDocs, collection, query, where, doc, getDoc } from '@firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from '../firebase/firebaseAuth';
import { ThemeProvider } from '@mui/system';
import theme from './Theme';

function CallStats() {
    const [stats, setStats] = useState([]);
    const [user, setUser] = useState(null);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
        });
        return unsubscribe;
    }, [auth]);

    useEffect(() => {
        if (user) {
            const fetchData = async () => {
                const userDoc = await getDoc(doc(db, "Users", user.uid));
                const userData = userDoc.data();

                const snapshot = await getDocs(query(collection(db, 'dispatches'), where('organizationID', '==', userData.organizationID)));
                const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                const computedStats = {};

                data.forEach(call => {
                    if (!computedStats[call.technician]) {
                        computedStats[call.technician] = {
                            dispatches: 0,
                            attempts: 0,
                            acknowledged: 0,
                            unacknowledged: 0
                        };
                    }

                    computedStats[call.technician].dispatches += 1;

                    computedStats[call.technician].attempts += call.attempts || 0;

                    if (call.acknowledged) {
                        computedStats[call.technician].acknowledged += 1;
                    } else {
                        computedStats[call.technician].unacknowledged += 1;
                    }
                });

                const statsArray = Object.entries(computedStats).map(([technician, stats]) => ({
                    technician,
                    ...stats,
                    acknowledgement: stats.dispatches ? (stats.dispatches / stats.attempts) * 100 : 0
                }));

                setStats(statsArray);
            };

            fetchData();
        }
    }, [user]);

    return (
        <ThemeProvider theme={theme}>
            {/* Desktop Table */}
            <Hidden mdDown>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Technician</TableCell>
                                <TableCell>Number of Dispatches</TableCell>
                                <TableCell>Number of Attempts</TableCell>
                                <TableCell>Acknowledgement (%)</TableCell>
                                <TableCell>Total Acknowledged</TableCell>
                                <TableCell>Total Unacknowledged</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stats.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell>{row.technician}</TableCell>
                                    <TableCell>{row.dispatches}</TableCell>
                                    <TableCell>{row.attempts}</TableCell>
                                    <TableCell>{row.acknowledgement.toFixed(2)}%</TableCell>
                                    <TableCell>{row.acknowledged}</TableCell>
                                    <TableCell>{row.unacknowledged}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Hidden>

            {/* Mobile Cards */}
            <Hidden lgUp>
                {stats.map((stat, i) => (
                    <Card key={i} sx={{ marginBottom: 2 }}>
                        <CardContent>
                            <Typography variant="h6">Technician: {stat.technician}</Typography>
                            <Typography>Number of Dispatches: {stat.dispatches}</Typography>
                            <Typography>Number of Attempts: {stat.attempts}</Typography>
                            <Typography>Acknowledgement (%): {stat.acknowledgement.toFixed(2)}</Typography>
                            <Typography>Total Acknowledged: {stat.acknowledged}</Typography>
                            <Typography>Total Unacknowledged: {stat.unacknowledged}</Typography>
                        </CardContent>
                    </Card>
                ))}
            </Hidden>
        </ThemeProvider>
    );
}

export default CallStats;
