import React from 'react';

function PurchaseOrder() {
  return (
    <div>
      <h1>PurchaseOrder Page</h1>
    </div>
  );
}

export default PurchaseOrder;
