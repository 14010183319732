import React, { useEffect, useState } from 'react';
import { doc, getDoc, query, where, collection, onSnapshot } from '@firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase/firebaseAuth';
import { Box, Button, Typography, ThemeProvider, Card, CardContent, Container, IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import theme from './Theme';

function Organization() {
  const [users, setUsers] = useState([]);
  const [orgInfo, setOrgInfo] = useState(null);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const fetchOrganization = async () => {
      const auth = getAuth();
      const currentUserSnapshot = await getDoc(doc(db, "Users", auth.currentUser.uid));
      const organizationID = currentUserSnapshot.data().organizationID;

      const orgRef = doc(db, 'Organizations', organizationID);
      const orgSnapshot = await getDoc(orgRef);
      if (orgSnapshot.exists()) {
        setOrgInfo(orgSnapshot.data());
      }

      const q = query(collection(db, "Users"), where("organizationID", "==", organizationID));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let users = [];
        querySnapshot.forEach((doc) => {
          users.push({ id: doc.id, ...doc.data() });
        });
        setUsers(users);
      });

      return () => unsubscribe();
    };

    fetchOrganization();
  }, []);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = async () => {
    if (orgInfo) {
      const orgRef = doc(db, 'Organizations', orgInfo.id);
      await orgRef.update({
        name: orgInfo.name,
        email: orgInfo.email,
        phone: orgInfo.phone,
        owner: orgInfo.owner,
        organizationTextID: orgInfo.organizationTextID
      });
      setEditing(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Box sx={{ my: 4 }}>
          {orgInfo && (
            <Card variant="outlined">
              <CardContent>
                {editing ? (
                  <>
                    <Typography variant="h5" gutterBottom>Edit Organization</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="h6">Organization Name:</Typography>
                      <input
                        type="text"
                        value={orgInfo.name}
                        onChange={(e) => setOrgInfo({ ...orgInfo, name: e.target.value })}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="h6">Email:</Typography>
                      <input
                        type="text"
                        value={orgInfo.email}
                        onChange={(e) => setOrgInfo({ ...orgInfo, email: e.target.value })}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="h6">Phone:</Typography>
                      <input
                        type="text"
                        value={orgInfo.phone}
                        onChange={(e) => setOrgInfo({ ...orgInfo, phone: e.target.value })}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="h6">Owner:</Typography>
                      <input
                        type="text"
                        value={orgInfo.owner}
                        onChange={(e) => setOrgInfo({ ...orgInfo, owner: e.target.value })}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="h6">Organization Text ID:</Typography>
                      <input
                        type="text"
                        value={orgInfo.organizationTextID}
                        onChange={(e) => setOrgInfo({ ...orgInfo, organizationTextID: e.target.value })}
                      />
                    </Box>
                    <Button onClick={handleSave}>Save Changes</Button>
                  </>
                ) : (
                  <>
                    <Typography variant="h5" gutterBottom>{orgInfo.name}</Typography>
                    <Typography variant="body1">Owner: {orgInfo.owner}</Typography>
                    <Typography variant="body1">Email: {orgInfo.email}</Typography>
                    <Typography variant="body1">Phone: {orgInfo.phone}</Typography>
                    <Typography variant="body1">Organization Text ID: {orgInfo.organizationTextID}</Typography>
                  </>
                )}
              </CardContent>
              {!editing && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', pr: 2 }}>
                  <IconButton onClick={handleEdit}><EditIcon /></IconButton>
                </Box>
              )}
            </Card>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Organization;
