import React, { useState, useEffect } from "react";
import { Button, TextField, Container, Typography } from "@mui/material";
import { doc, getDoc, setDoc } from "@firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../firebase/firebaseAuth";


function Config() {
  const [ttsTemplate, setTtsTemplate] = useState("");
  const auth = getAuth();
  const userId = auth.currentUser.uid;

  useEffect(() => {
    const fetchData = async () => {
      const userDocRef = doc(db, "Users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const orgDocRef = doc(db, "Organizations", userData.organizationID);
        const orgDocSnap = await getDoc(orgDocRef);

        if (orgDocSnap.exists()) {
          const orgData = orgDocSnap.data();
          setTtsTemplate(orgData.ttsTemplate || "");
        }
      }
    };

    fetchData();
  }, [userId]);

  const saveConfig = async () => {
    const userDocRef = doc(db, "Users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const orgDocRef = doc(db, "Organizations", userData.organizationID);
      await setDoc(orgDocRef, { ttsTemplate }, { merge: true });
    }
  };

  return (
    <Container maxWidth="md" maxHeight="md">
      <Typography variant="h4" component="h2" gutterBottom>
        Call Message Settings
      </Typography>
      <TextField
        multiline={true}
        rows={8}
        id="tts-template"
        label="Call Body"
        fullWidth
        value={ttsTemplate}
        onChange={(e) => setTtsTemplate(e.target.value)}
        helperText="Currently available variables: {Technician First Name}, {Technician Last Name}"
      />
      <Button variant="contained" color="primary" onClick={saveConfig}>
        Save
      </Button>
    </Container>
  );
}

export default Config;
