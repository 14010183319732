import React, { useEffect, useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Card, CardContent, Typography, Hidden } from '@mui/material';
import { doc, onSnapshot, collection, deleteDoc, updateDoc, setDoc, getDoc, query, where } from '@firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase/firebaseAuth';
import { ThemeProvider } from '@mui/system';
import theme from './Theme';

function Users() {
    const [users, setUsers] = useState([]);
    const [openAddUser, setOpenAddUser] = useState(false);
    const [openEditUser, setOpenEditUser] = useState(false);
    const [newUser, setNewUser] = useState({
        "First Name": '',
        "Last Name": '',
        email: '',
        phone: '',
        Roles: '',
    });
    const [editingUser, setEditingUser] = useState({
        id: null,
        "First Name": '',
        "Last Name": '',
        email: '',
        phone: '',
        Roles: '',
    });

    useEffect(() => {
        const fetchUsers = async () => {
            const auth = getAuth();
            const currentUserSnapshot = await getDoc(doc(db, "Users", auth.currentUser.uid));
            const organizationID = currentUserSnapshot.data().organizationID;
            
            const q = query(collection(db, "Users"), where("organizationID", "==", organizationID));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                let users = [];
                querySnapshot.forEach((doc) => {
                    users.push({ id: doc.id, ...doc.data() });
                });
                setUsers(users);
            });

            return () => unsubscribe();
        };

        fetchUsers();
    }, []);

    const handleAddUserSubmit = async (event) => {
        event.preventDefault();
        const auth = getAuth();
    
        const currentUserSnapshot = await getDoc(doc(db, "Users", auth.currentUser.uid));
        const organizationID = currentUserSnapshot.data().organizationID;
        
        // Format the phone number
        const phone = "+1" + newUser.phone.replace(/\D/g, '');
        
        try {
            const userData = {
                "First Name": newUser["First Name"],
                "Last Name": newUser["Last Name"],
                email: newUser.email,
                phone: newUser.phone,
                Roles: newUser.Roles,
                organizationID: organizationID,
            };
            
            const inviteID = (Date.now() + Math.random()).toString();
            await setDoc(doc(db, "Invites", inviteID), userData);
    
            const response = await fetch(
                'https://us-central1-smstocall2.cloudfunctions.net/sendInviteEmail', 
                {
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        uid: auth.currentUser.uid,
                        inviteId: inviteID,
                        inviteLink: `https://companionpro.tech/signup/${inviteID}`,
                    }),
                }
            );
    
            const responseJson = await response.json();
            if (responseJson.status !== 'success') {
                throw new Error(responseJson.message);
            }
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error("Error code:", errorCode, "Error message:", errorMessage);
        }
        setOpenAddUser(false);
    };
    
    const handleAddUserChange = (event) => {
        setNewUser({ ...newUser, [event.target.name]: event.target.value });
    };

    const handleDeleteUser = async (userId) => {
        await deleteDoc(doc(db, "Users", userId));
    };

    const handleEditUserChange = (event) => {
        setEditingUser({ ...editingUser, [event.target.name]: event.target.value });
    };

    const handleEditUserSubmit = async (event) => {
        event.preventDefault();
        await updateDoc(doc(db, "Users", editingUser.id), {
            "First Name": editingUser["First Name"],
            "Last Name": editingUser["Last Name"],
            email: editingUser.email,
            phone: editingUser.phone,
            Roles: editingUser.Roles,
        });
        setOpenEditUser(false);
    };

    const handleEditUser = (user) => {
        setEditingUser(user);
        setOpenEditUser(true);
    };

    return (
        <ThemeProvider theme={theme}>
            <div>
                <Button variant="contained" color="primary" onClick={() => setOpenAddUser(true)}>Add User</Button>
                <Dialog open={openAddUser} onClose={() => setOpenAddUser(false)}>
                    <form onSubmit={handleAddUserSubmit}>
                        <DialogTitle>Add User</DialogTitle>
                        <DialogContent>
                            <TextField autoFocus margin="dense" name="First Name" label="First Name" type="text" fullWidth onChange={handleAddUserChange} />
                            <TextField margin="dense" name="Last Name" label="Last Name" type="text" fullWidth onChange={handleAddUserChange} />
                            <TextField margin="dense" name="email" label="Email" type="email" fullWidth onChange={handleAddUserChange} />
                            <TextField margin="dense" name="phone" label="Phone" type="tel" fullWidth onChange={handleAddUserChange} />
                            <TextField margin="dense" name="Roles" label="Roles" type="text" fullWidth onChange={handleAddUserChange} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenAddUser(false)}>Cancel</Button>
                            <Button type="submit">Add User</Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog open={openEditUser} onClose={() => setOpenEditUser(false)}>
                    <form onSubmit={handleEditUserSubmit}>
                        <DialogTitle>Edit User</DialogTitle>
                        <DialogContent>
                            <TextField autoFocus margin="dense" name="First Name" label="First Name" type="text" fullWidth value={editingUser["First Name"]} onChange={handleEditUserChange} />
                            <TextField margin="dense" name="Last Name" label="Last Name" type="text" fullWidth value={editingUser["Last Name"]} onChange={handleEditUserChange} />
                            <TextField margin="dense" name="email" label="Email" type="email" fullWidth value={editingUser.email} onChange={handleEditUserChange} />
                            <TextField margin="dense" name="phone" label="Phone" type="tel" fullWidth value={editingUser.phone} onChange={handleEditUserChange} />
                            <TextField margin="dense" name="Roles" label="Roles" type="text" fullWidth value={editingUser.Roles} onChange={handleEditUserChange} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenEditUser(false)}>Cancel</Button>
                            <Button type="submit">Save</Button>
                        </DialogActions>
                    </form>
                </Dialog>

                {/* Desktop Table */}
                <Hidden mdDown>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Edit</TableCell>
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user) => (
                                    <TableRow key={user.id}>
                                        <TableCell>{user["First Name"]} {user["Last Name"]}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.phone}</TableCell>
                                        <TableCell>{user.Roles}</TableCell>
                                        <TableCell><Button onClick={() => handleEditUser(user)}>Edit</Button></TableCell>
                                        <TableCell><Button onClick={() => handleDeleteUser(user.id)}>Delete</Button></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Hidden>

                {/* Mobile Cards */}
                <Hidden mdUp>
                    {users.map((user) => (
                        <Card key={user.id}>
                            <CardContent>
                                <Typography>Name: {user["First Name"]} {user["Last Name"]}</Typography>
                                <Typography>Email: {user.email}</Typography>
                                <Typography>Phone: {user.phone}</Typography>
                                <Typography>Role: {user.Roles}</Typography>
                            </CardContent>
                            <DialogActions>
                                <Button color="primary" onClick={() => handleEditUser(user)}>Edit</Button>
                                <Button color="secondary" onClick={() => handleDeleteUser(user.id)}>Delete</Button>
                            </DialogActions>
                        </Card>
                    ))}
                </Hidden>
            </div>
        </ThemeProvider>
    );
}

export default Users;
