import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { collection, addDoc, updateDoc, deleteDoc, doc, Timestamp, getFirestore, getDocs, getDoc, query, where } from "firebase/firestore"; 
import { auth, db } from '../firebase/firebaseAuth';
import { useAuthState } from 'react-firebase-hooks/auth';
import useMediaQuery from '@mui/material/useMediaQuery';

function Schedule() {
  const [user] = useAuthState(auth);
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const isDesktop = useMediaQuery('(min-width:600px)');

  const handleClickOpen = (event) => {
    setCurrentEvent(event);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentEvent(null);
  };

  const handleInputChange = (event) => {
    let value = event.target.value;

    if (['startDate', 'endDate', 'startTime', 'endTime'].includes(event.target.name)) {
      const dateTimePart = event.target.name.endsWith('Date') ? 'Date' : 'Time';
      const otherPartName = `${event.target.name.slice(0, -dateTimePart.length)}${dateTimePart === 'Date' ? 'Time' : 'Date'}`;
      const otherPartValue = currentEvent[otherPartName];
      if (otherPartValue) {
        value = new Date(`${event.target.name.endsWith('Date') ? value : otherPartValue}T${event.target.name.endsWith('Time') ? value : otherPartValue}`);
      } else {
        value = null;
      }
    }

    setCurrentEvent(prevState => ({
      ...prevState,
      [event.target.name]: value
    }));
  };

  const handleUpdate = async () => {
    try {
      await updateDoc(doc(db, 'Events', currentEvent.id), {
        ...currentEvent,
        start: currentEvent.start?.getTime() || null,
        end: currentEvent.end?.getTime() || null,
        updatedAt: Timestamp.now()
      });
      handleClose();
    } catch (error) {
      console.error("Error updating event: ", error);
    }
  };

  const handleCreate = async () => {
    try {
      await addDoc(collection(db, 'Events'), {
        ...currentEvent,
        start: currentEvent.start?.getTime() || null,
        end: currentEvent.end?.getTime() || null,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        organizationID: orgId
      });
      handleClose();
    } catch (error) {
      console.error("Error creating event: ", error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, 'Events', currentEvent.id));
      handleClose();
    } catch (error) {
      console.error("Error deleting event: ", error);
    }
  };

  const fetchEvents = async () => {
    const eventsRef = collection(db, "Events");
    const q = query(eventsRef, where("organizationID", "==", orgId));
    const querySnapshot = await getDocs(q);
    const fetchedEvents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      start: new Date(doc.data().start),
      end: new Date(doc.data().end),
    }));
    setEvents(fetchedEvents);
  };
  
  const fetchOrganization = async () => {
    const userDoc = doc(db, "Users", user.uid);
    const userData = await getDoc(userDoc);
    setOrgId(userData.data().organizationID);
  };
  
  useEffect(() => {
    if(user) {
      fetchOrganization().then(fetchEvents);
    }
  }, [user]);

  return (
    <div>
      {orgId && (
        <>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: isDesktop ? 'dayGridMonth,timeGridWeek' : 'dayGridMonth,timeGridDay'
            }}
            initialView={isDesktop ? 'timeGridWeek' : 'timeGridDay'}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            initialEvents={events} // alternatively, use the `events` setting to fetch from a feed
            select={handleClickOpen}
            eventClick={handleClickOpen}
            eventsSet={setEvents} // called after events are initialized/added/changed/removed
          />

          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Event</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To edit this event, please change the information below.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                name="title"
                label="Title"
                type="text"
                fullWidth
                value={currentEvent?.title || ''}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="startDate"
                label="Start Date"
                type="date"
                fullWidth
                value={currentEvent?.start ? new Date(currentEvent.start).toISOString().slice(0,10) : ''}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="startTime"
                label="Start Time"
                type="time"
                fullWidth
                value={currentEvent?.start ? new Date(currentEvent.start).toISOString().slice(11,16) : ''}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="endDate"
                label="End Date"
                type="date"
                fullWidth
                value={currentEvent?.end ? new Date(currentEvent.end).toISOString().slice(0,10) : ''}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="endTime"
                label="End Time"
                type="time"
                fullWidth
                value={currentEvent?.end ? new Date(currentEvent.end).toISOString().slice(11,16) : ''}
                onChange={handleInputChange}
              />
              <TextField
                margin="dense"
                name="location"
                label="Location"
                type="text"
                fullWidth
                value={currentEvent?.location || ''}
                onChange={handleInputChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={currentEvent?.id ? handleUpdate : handleCreate} color="primary">
                {currentEvent?.id ? 'Update' : 'Create'}
              </Button>
              {currentEvent?.id && (
                <Button onClick={handleDelete} color="primary">
                  Delete
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
}

export default Schedule;
