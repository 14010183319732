import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from '../firebase/firebaseAuth';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import theme from './Theme';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  
  const navigate = useNavigate();

  const signIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);

      const userDocRef = doc(db, 'Users', auth.currentUser.uid);
      if (!(await userDocRef.get()).exists()) {
        setDoc(userDocRef, {
          email: auth.currentUser.email,
          firstName: '',
          lastName: '',
        });
      }

      navigate('/homepage'); // navigate to homepage
    } catch (error) {
      console.error("Failed to sign in", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      console.log("Password reset email sent");
    } catch (error) {
      console.error("Failed to send password reset email", error);
    }

    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container direction="column" justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <Paper elevation={1} style={{backgroundColor: '#3f51b5', padding: theme.spacing(10), borderRadius: '15px'}}>
        <Box marginLeft={2} marginTop={-8} marginBottom={1} color="#fff"><h1>CompanionPro</h1></Box>          
        <Box marginBottom={2}>
            <TextField
              label="Email"
              type="text"
              value={email}
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              style={{backgroundColor: '#fff'}}
            />
          </Box>
          <Box marginBottom={2}>
            <TextField
              label="Password"
              type="password"
              value={password}
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              style={{backgroundColor: '#fff'}}
            />
          </Box>
          <Box display="flex" gap={2}>
            <Button variant="contained" color="secondary" onClick={signIn}>
              Login
            </Button>
            <Button color="secondary" onClick={handleClickOpen}>
              Forgot password
            </Button>
          </Box>
        </Paper>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{"Forgot password"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To reset your password, please enter your email address here. We will send updates to your email.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Email Address"
              type="email"
              fullWidth
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handlePasswordReset} color="primary">
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </ThemeProvider>
  );
}

export default Login;
