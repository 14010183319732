import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import OrganizationIcon from '@mui/icons-material/Business'; // Update these icons as required
import UsersIcon from '@mui/icons-material/Group';
import ProfileIcon from '@mui/icons-material/Person';
import IntegrationIcon from '@mui/icons-material/IntegrationInstructions';
import BillingIcon from '@mui/icons-material/Payment';

export default function Settings() {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Box display="flex">
      <Box width={collapsed ? 60 : 170} borderRight="1px solid gray">
        <IconButton onClick={toggleCollapse}>
          {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
        <List component="nav">
          <ListItem button component={Link} to="/settings/organization">
            <OrganizationIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'} ml={1}><ListItemText primary="Organization" /></Box>
          </ListItem>
          <ListItem button component={Link} to="/settings/users">
            <UsersIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'} ml={1}><ListItemText primary="Users" /></Box>
          </ListItem>
          <ListItem button component={Link} to="/settings/profile">
            <ProfileIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'} ml={1}><ListItemText primary="Profile" /></Box>
          </ListItem>
          <ListItem button component={Link} to="/settings/integrations">
            <IntegrationIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'} ml={1}><ListItemText primary="Integrations" /></Box>
          </ListItem>
          <ListItem button component={Link} to="/settings/billing">
            <BillingIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'} ml={1}><ListItemText primary="Billing" /></Box>
          </ListItem>
        </List>
      </Box>
      <Box p={1} flex="auto">
        <Outlet />
      </Box>
    </Box>
  );
}
