import React from 'react';

function StockIn() {
  return (
    <div>
      <h1>StockIn Page</h1>
    </div>
  );
}

export default StockIn;
