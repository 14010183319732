import React, { useEffect, useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, deleteDoc, setDoc } from '@firebase/firestore';
import { db } from '../firebase/firebaseAuth';
import { TextField, Button, ThemeProvider, Container, Typography, Box } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import theme from './Theme';

function Signup(props) {
    const [password, setPassword] = useState('');
    const [inviteData, setInviteData] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();  // Use useNavigate instead of useHistory

    // Extract the inviteID from the URL
    const { inviteID } = useParams();

    useEffect(() => {
        const fetchInviteData = async () => {
            const inviteSnapshot = await getDoc(doc(db, "Invites", inviteID));
            if (inviteSnapshot.exists) {
                setInviteData(inviteSnapshot.data());
            }
        };
        fetchInviteData();
    }, [inviteID]);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSignup = async () => {
        if (password.length < 6) {
            setError("Password must be at least 6 characters");
            return;
        }
        try {
            const auth = getAuth();
            const userCredential = await createUserWithEmailAndPassword(auth, inviteData.email, password); 
            await deleteDoc(doc(db, "Invites", inviteID));
            
            // Get the uid of the newly created user
            const uid = userCredential.user.uid;

            // Create a new user document in the Users collection
            await setDoc(doc(db, "Users", uid), {
                email: inviteData.email,
                organizationID: inviteData.organizationID,
                Roles: inviteData.Roles,
                "First Name": inviteData["First Name"],
                "Last Name": inviteData["Last Name"]
            });

            // Redirect the user to Home page
            navigate('/Login');

        } catch (error) {
            setError(error.message);
        }
    };

    if (!inviteData) {
        return <div>Loading...</div>;
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="sm">
                <Box sx={{ mt: 8, mb: 4 }}>
                    <Typography variant="h4" align="center">
                        Welcome, {inviteData["First Name"]}!
                    </Typography>
                </Box>
                <Box component="form" sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    <Button 
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSignup}
                    >
                        Sign Up
                    </Button>
                    {error && <div>{error}</div>}
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Signup;
