import React from 'react';

function Notifications() {
  return (
    <div>
      <h1>Notifications Page</h1>
    </div>
  );
}

export default Notifications;
