import React, { useEffect, useState } from 'react';
import { getDocs, collection, query, where, doc, getDoc } from '@firebase/firestore';
import { getAuth } from "firebase/auth";
import { db } from '../firebase/firebaseAuth';
import { Typography, Paper, Box, Divider, Button, useMediaQuery, useTheme } from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { ThemeProvider } from '@mui/material/styles';
import Theme from './Theme';

const CustomNextArrow = ({ onClickHandler, hasNext }) => 
    hasNext && (
        <Button variant="contained" onClick={onClickHandler}>Next</Button>
    );

const CustomPrevArrow = ({ onClickHandler, hasPrev }) => 
    hasPrev && (
        <Button variant="contained" onClick={onClickHandler}>Prev</Button>
    );

function Dashboard() {
    const [userName, setUserName] = useState("");
    const [userOrgId, setUserOrgId] = useState("");
    const [userStats, setUserStats] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const fetchUser = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            const userSnapshot = await getDoc(doc(db, 'Users', user.uid));
            const userData = userSnapshot.data();

            if (userData) {
                setUserName(`${userData["First Name"]} ${userData["Last Name"]}`);
                setUserOrgId(userData.organizationID);
            }
        };
        
        const fetchStats = async () => {
            const snapshot = await getDocs(query(collection(db, 'dispatches'), where('organizationID', '==', userOrgId), where('technician', '==', userName)));
            const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            let dispatches = 0;
            let attempts = 0;
            let acknowledged = 0;
            let unacknowledged = 0;
            
            data.forEach(call => {
                dispatches += 1;
                attempts += call.attempts || 0;
                if (call.acknowledged) acknowledged += 1;
                else unacknowledged += 1;
            });

            const acknowledgementRate = dispatches ? (dispatches / attempts) * 100 : 0;

            setUserStats({ dispatches, attempts, acknowledged, unacknowledged, acknowledgementRate });
        };

        fetchUser().then(fetchStats);
    }, [userOrgId, userName]);

    const renderStats = () => (
        <>
            <Box>
                <Typography variant="subtitle1">Number of Dispatches:</Typography>
                <Typography variant="h4">{userStats.dispatches}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box>
                <Typography variant="subtitle1">Number of Attempts:</Typography>
                <Typography variant="h4">{userStats.attempts}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box>
                <Typography variant="subtitle1">Total Acknowledged:</Typography>
                <Typography variant="h4">{userStats.acknowledged}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box>
                <Typography variant="subtitle1">Total Unacknowledged:</Typography>
                <Typography variant="h4">{userStats.unacknowledged}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box>
                <Typography variant="subtitle1">Acknowledgement Rate (%):</Typography>
                <Typography variant="h4">{userStats.acknowledgementRate.toFixed(2)}</Typography>
            </Box>
        </>
    );

    return (
        <ThemeProvider theme={Theme}>
            <Box>
                <Typography variant="h5">Hey, {userName}</Typography>
                {userStats && (
                    <Paper sx={{ padding: 3, marginTop: 3}}>
                        <Typography variant="h6" gutterBottom>Call Stats</Typography>
                        {isMobile ? (
                            <Carousel 
                                renderArrowNext={(onClickHandler, hasNext) => <CustomNextArrow onClickHandler={onClickHandler} hasNext={hasNext} />} 
                                renderArrowPrev={(onClickHandler, hasPrev) => <CustomPrevArrow onClickHandler={onClickHandler} hasPrev={hasPrev} />}
                                showThumbs={false}
                                showStatus={false}>
                                {renderStats()}
                            </Carousel>
                        ) : (
                            <Box display="flex" alignItems="center" justifyContent="space-around">
                                {renderStats()}
                            </Box>
                        )}
                    </Paper>
                )}
            </Box>
        </ThemeProvider>
    );
}

export default Dashboard;
