import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import PurchaseRequestIcon from '@mui/icons-material/ShoppingBasket';
import PurchaseOrderIcon from '@mui/icons-material/Assignment';
import StockListIcon from '@mui/icons-material/List';
import StockInIcon from '@mui/icons-material/ArrowCircleDown';
import StockOutIcon from '@mui/icons-material/ArrowCircleUp';
import TransferIcon from '@mui/icons-material/SyncAlt';
import AdjustIcon from '@mui/icons-material/Tune';
import HistoryIcon from '@mui/icons-material/History';
import DataCenterIcon from '@mui/icons-material/DataUsage';
import LocationsIcon from '@mui/icons-material/LocationOn';
import ProductsIcon from '@mui/icons-material/LocalOffer';
import CategoryIcon from '@mui/icons-material/Category';
import VendorsIcon from '@mui/icons-material/Group';

export default function Inventory() {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Box display="flex">
      <Box width={collapsed ? 60 : 190} borderRight="1px solid gray">
        <IconButton onClick={toggleCollapse}>
          {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
        <List component="nav">
          <ListItem button component={Link} to="/inventory/purchase-request">
            <PurchaseRequestIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'}><ListItemText primary={<span style={{whiteSpace: 'nowrap'}}>PurchaseRequest</span>} /></Box>
          </ListItem>
          <ListItem button component={Link} to="/inventory/purchase-order">
            <PurchaseOrderIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'}><ListItemText primary={<span style={{whiteSpace: 'nowrap'}}>PurchaseOrder</span>} /></Box>
          </ListItem>
          <ListItem button component={Link} to="/inventory/stock-list">
            <StockListIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'}><ListItemText primary={<span style={{whiteSpace: 'nowrap'}}>StockList</span>} /></Box>
          </ListItem>
          <ListItem button component={Link} to="/inventory/stock-in">
            <StockInIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'}><ListItemText primary={<span style={{whiteSpace: 'nowrap'}}>StockIn</span>} /></Box>
          </ListItem>
          <ListItem button component={Link} to="/inventory/stock-out">
            <StockOutIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'}><ListItemText primary={<span style={{whiteSpace: 'nowrap'}}>StockOut</span>} /></Box>
          </ListItem>
          <ListItem button component={Link} to="/inventory/transfer">
            <TransferIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'}><ListItemText primary={<span style={{whiteSpace: 'nowrap'}}>Transfer</span>} /></Box>
          </ListItem>
          <ListItem button component={Link} to="/inventory/adjust">
            <AdjustIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'}><ListItemText primary={<span style={{whiteSpace: 'nowrap'}}>Adjust</span>} /></Box>
          </ListItem>
          <ListItem button component={Link} to="/inventory/history">
            <HistoryIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'}><ListItemText primary={<span style={{whiteSpace: 'nowrap'}}>History</span>} /></Box>
          </ListItem>
          <ListItem button component={Link} to="/inventory/data-center">
            <DataCenterIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'}><ListItemText primary={<span style={{whiteSpace: 'nowrap'}}>DataCenter</span>} /></Box>
          </ListItem>
          <ListItem button component={Link} to="/inventory/data-center/locations">
            <LocationsIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'}><ListItemText primary={<span style={{whiteSpace: 'nowrap'}}>Locations</span>} /></Box>
          </ListItem>
          <ListItem button component={Link} to="/inventory/data-center/products">
            <ProductsIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'}><ListItemText primary={<span style={{whiteSpace: 'nowrap'}}>Products</span>} /></Box>
          </ListItem>
          <ListItem button component={Link} to="/inventory/data-center/category">
            <CategoryIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'}><ListItemText primary={<span style={{whiteSpace: 'nowrap'}}>Category</span>} /></Box>
          </ListItem>
          <ListItem button component={Link} to="/inventory/data-center/vendors">
            <VendorsIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'}><ListItemText primary={<span style={{whiteSpace: 'nowrap'}}>Vendors</span>} /></Box>
          </ListItem>
        </List>
      </Box>
      <Box p={1} flex="auto">
        <Outlet />
      </Box>
    </Box>
  );
}
