import { initializeApp } from '@firebase/app';
import { getAuth } from '@firebase/auth';
import { getFirestore } from '@firebase/firestore';
import firebaseConfig from './firebaseConfig';

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Export the auth module
export const auth = getAuth(app);

// Export the Firestore instance
export const db = getFirestore(app);
