import React from 'react';

function Vendors() {
  return (
    <div>
      <h1>Vendors Page</h1>
    </div>
  );
}

export default Vendors;
