import React from 'react';

function StockList() {
  return (
    <div>
      <h1>StockList Page</h1>
    </div>
  );
}

export default StockList;
