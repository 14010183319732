import React from 'react';

function StockHistory() {
  return (
    <div>
      <h1>StockHistory Page</h1>
    </div>
  );
}

export default StockHistory;
