import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import SummaryIcon from '@mui/icons-material/Assessment'; // Update these icons as required
import AnalysisIcon from '@mui/icons-material/PieChart';
import NotificationIcon from '@mui/icons-material/Notifications';
import LowStockIcon from '@mui/icons-material/ReportProblem';
import CallStatsIcon from '@mui/icons-material/BarChart';
import UnacknowledgedDispatchIcon from '@mui/icons-material/Error';

export default function Reports() {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Box display="flex">
      <Box width={collapsed ? 60 : 190} borderRight="1px solid gray">
        <IconButton onClick={toggleCollapse}>
          {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
        <List component="nav">
          <ListItem button component={Link} to="/reports/summary">
            <SummaryIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'} ml={1}><ListItemText primary="Summary" /></Box>
          </ListItem>
          <ListItem button component={Link} to="/reports/callstats">
            <CallStatsIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'} ml={1}><ListItemText primary="CallStats" /></Box>
          </ListItem>
          <ListItem button component={Link} to="/reports/Notifacations">
            <AnalysisIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'} ml={1}><ListItemText primary="InventoryAnalysis" /></Box>
          </ListItem>
          <ListItem button component={Link} to="/reports/notifications">
            <NotificationIcon />
            <Box visibility={collapsed ? 'hidden' : 'visible'} ml={1}><ListItemText primary="Notifications" /></Box>
          </ListItem>
        </List>
      </Box>
      <Box p={1} flex="auto">
        <Outlet />
      </Box>
    </Box>
  );
}
