import React from 'react';

function Billing() {
  return (
    <div>
      <h1>Billing Page</h1>
    </div>
  );
}

export default Billing;
