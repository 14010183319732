import React from 'react';

function Products() {
  return (
    <div>
      <h1>Products Page</h1>
    </div>
  );
}

export default Products;
