import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase/firebaseAuth';
import { onAuthStateChanged, signOut, updatePassword } from '@firebase/auth';
import { doc, getDoc, setDoc, updateDoc } from '@firebase/firestore';
import { Box, Button, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogActions, ThemeProvider, Stack, Card, CardContent, IconButton, Container, Avatar } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import theme from './Theme';

function ProfilePage() {
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");  
  const [orgName, setOrgName] = useState(""); 
  const [phoneNumber, setPhoneNumber] = useState(""); 
  const [editing, setEditing] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    const authObserver = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        fetchUserData(user.uid);
      }
    });
    return authObserver;
  }, []);

  const fetchUserData = async (uid) => {
    const docRef = doc(db, 'Users', uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setFirstName(docSnap.data()["First Name"]);  
      setLastName(docSnap.data()["Last Name"]);  
      setRole(docSnap.data().Roles);
      setPhoneNumber(docSnap.data().phone); // Set phone number to the user's phone number  
      const orgRef = doc(db, 'Organizations', docSnap.data().organizationID);
      const orgSnap = await getDoc(orgRef);
      if (orgSnap.exists()) {
        setOrgName(orgSnap.data().name);  
      }
    } else {
      console.log("No such document!");
    }
  }
  

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Sign Out Error:', error);
    }
  };

  const handleSave = async () => {
    if (user) {
      const userRef = doc(db, 'Users', user.uid);
      await updateDoc(userRef, { "First Name": firstName, "Last Name": lastName }, { merge: true });
      setEditing(false); 
    }
  }

  const handleChangePassword = async () => {
    if (user) {
      await updatePassword(auth.currentUser, password);
      setChangingPassword(false);
    }
  }

  const handleEdit = () => {
    setEditing(true); 
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Box sx={{ my: 4 }}>
          <Card variant="outlined">
            <CardContent>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Avatar>{firstName[0]}{lastName[0]}</Avatar>
                  <Typography variant="h5" sx={{ ml: 1 }}>{firstName} {lastName}</Typography>
                </Box>
                <IconButton onClick={handleEdit}><EditIcon /></IconButton>
              </Stack>
              <br></br>
              <Typography variant="body1">Phone Number: {phoneNumber}</Typography>
              <Typography variant="body1">Email: {user?.email}</Typography>
              <Typography variant="body1">Organization: {orgName}</Typography>
              <Typography variant="body1">Role: {role}</Typography>
              <Dialog open={editing} onClose={() => setEditing(false)}>
                <DialogTitle>Edit User</DialogTitle>
                <DialogContent>
                  <TextField autoFocus margin="dense" name="First Name" label="First Name" type="text" fullWidth value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                  <TextField margin="dense" name="Last Name" label="Last Name" type="text" fullWidth value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setEditing(false)}>Cancel</Button>
                  <Button onClick={handleSave}>Save Changes</Button>
                </DialogActions>
              </Dialog>
              <Dialog open={changingPassword} onClose={() => setChangingPassword(false)}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                  <TextField autoFocus margin="dense" name="password" label="New Password" type="password" fullWidth value={password} onChange={(e) => setPassword(e.target.value)} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setChangingPassword(false)}>Cancel</Button>
                  <Button onClick={handleChangePassword}>Change Password</Button>
                </DialogActions>
              </Dialog>
            </CardContent>
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" color="primary" onClick={() => setChangingPassword(true)}>Change Password</Button>
              <Button variant="contained" color="secondary" onClick={handleSignOut}>Sign Out</Button>
            </Box>
          </Card>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default ProfilePage;
