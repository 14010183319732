import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Login from './components/Login';
import HomePage from './components/HomePage';
import Users from './components/Users';
import Integrations from './components/Integrations';
import Billing from './components/Billing';
import Profile from './components/ProfilePage';
import Notification from './components/Notifications';
import Organization from './components/Organization';
import CallStats from './components/CallStats';
import Signup from './components/SignUp';
import PurchaseRequest from './components/PurchaseRequest';
import PurchaseOrder from './components/PurchaseOrder';
import StockList from './components/StockList';
import StockIn from './components/StockIn';
import StockOut from './components/StockOut';
import Transfer from './components/Transfer';
import Adjust from './components/Adjust';
import History from './components/StockHistory';
import Locations from './components/Locations';
import Products from './components/Products';
import Category from './components/Category';
import Vendors from './components/Vendors';
import Summary from './components/Summary';
import InventoryAnalysis from './components/InventoryAnalysis';
import LowStockReminder from './components/LowStockReminder';
import UnacknowledgedDispatch from './components/UnacknowledgedDispatch';
import ShiftScheduler from './components/ShiftScheduler';
import Config from './components/Config';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import theme from './components/Theme';

// Import the new components
import Inventory from './components/Inventory';
import Dispatcher from './components/Dispatcher';
import Reports from './components/Reports';
import Settings from './components/Settings';

function App() {
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const themeInstance = useTheme();
  const isMobile = useMediaQuery(themeInstance.breakpoints.down('sm'));

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (currentUser) => {
      setUser(currentUser);
    });
    return unsubscribe;
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuItems = ['Homepage', 'Inventory', 'Dispatcher', 'Reports', 'Settings'];

  const renderMobileMenu = () => (
    <Drawer open={open} onClose={handleDrawerClose}>
      <List>
        {menuItems.map((text) => (
          <ListItem button key={text} component={Link} to={`/${text.toLowerCase()}`} onClick={handleDrawerClose}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );

  const renderDesktopMenu = () => (
    <Toolbar>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        CompanionPro
      </Typography>
      {menuItems.map((text) => (
        <Button color="inherit" component={Link} to={`/${text.toLowerCase()}`} style={{ textTransform: 'none' }}>{text}</Button>
      ))}
    </Toolbar>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        {user && (
          <AppBar position="static">
            {isMobile ? (
              <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  CompanionPro
                </Typography>
              </Toolbar>
            ) : renderDesktopMenu()}
          </AppBar>
        )}
        {isMobile && renderMobileMenu()}
        <Routes>
          <Route path="/signup/:inviteID" element={<Signup />} />
          {!user ? (
            <Route path="/*" element={<Login />} />
          ) : (
            <>
              <Route path="/" element={<HomePage />} />
              <Route path="/homepage" element={<HomePage />} />
              <Route path="/inventory/*" element={<Inventory />}>
                <Route path="purchaserequest" element={<PurchaseRequest />} />
                <Route path="purchaseorder" element={<PurchaseOrder />} />
                <Route path="stocklist" element={<StockList />} />
                <Route path="stockin" element={<StockIn />} />
                <Route path="stockout" element={<StockOut />} />
                <Route path="transfer" element={<Transfer />} />
                <Route path="adjust" element={<Adjust />} />
                <Route path="history" element={<History />} />
                <Route path="locations" element={<Locations />} />
                <Route path="products" element={<Products />} />
                <Route path="category" element={<Category />} />
                <Route path="vendors" element={<Vendors />} />
              </Route>
              <Route path="/dispatcher/*" element={<Dispatcher />}>
                <Route path="messages" element={<Config />} />
                <Route path="shiftscheduler" element={<ShiftScheduler />} />
              </Route>
              <Route path="/reports/*" element={<Reports />}>
                <Route path="callstats" element={<CallStats />} />
                <Route path="inventoryanalysis" element={<InventoryAnalysis />} />
                <Route path="notifications" element={<Notification />} />
              </Route>
              <Route path="/settings/*" element={<Settings />}>
                <Route path="organization" element={<Organization />} />
                <Route path="users" element={<Users />} />
                <Route path="profile" element={<Profile />} />
                <Route path="integrations" element={<Integrations />} />
                <Route path="billing" element={<Billing />} />
              </Route>
              <Route path="*" element={<Navigate to="/homepage" />} />
            </>
          )}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
